html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  background-color: #1b1b1f;
}

#root {
  height: 100%;
}
