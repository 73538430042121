.ant-modal,
.ant-modal-content {
  width: 600px;
  height: 300px;
  @media (max-width: 800px) {
    height: 98vh;
    width: 96vw;
    margin: 0;
    top: 0;
  }
}
.ant-modal-body {
  @media (max-width: 800px) {
    height: calc(100vh - 300px);
  }
}
